@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/fonts/MyriadPro/Myriad\ Pro\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/fonts/MyriadPro/Myriad\ Pro\ Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/fonts/MyriadPro/Myriad\ Pro\ Light.otf') format('opentype');
  font-weight: light;
  font-style: normal;
}

@layer base {
  .title {
    font-family: 'Myriad Pro', sans-serif;
    @apply font-bold text-[14px] text-[#1F2846];
  }

  .subtitle {
    font-family: 'Myriad Pro', sans-serif;
    @apply font-bold text-[14px] text-black;
  }

  .text {
    font-family: 'Myriad Pro', sans-serif;
    @apply font-normal text-[11px] text-black;
  }
}